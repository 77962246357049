.mainslider{
    min-height: 526px;
    overflow: hidden;
    max-height:90vh;
    .slide{
        max-height: 736px;
    }
}
.slider{
    position: relative;
    .bx-viewport{
        height: auto !important;
    }
    &>.bx-wrapper{
        margin: 0;
        .bx-pager{
            position: absolute;
            bottom:30px;
            left:0;
            width:15%;
            z-index: 99;
        }
    }
    .pos{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
    }
    .wrapper{
        position: relative;
        height: 100%;
    }
    &-cont{
        position: absolute;
        background-color: rgba($color-second, 0.95);
        bottom:33px;
        left:156px;
        width: 566px;
        padding: 36px 40px;
        color:#fff;
        @extend .p;
        @include font-size-root(17);
        line-height: 21px;
        text-align: left;
        h1{
            font-weight: 900;
            @include font-size-root(37);
            line-height: 45px;
            text-transform: uppercase;
            font-style: italic;
            padding-bottom:0px;
            max-height: 480px;
            overflow: hidden;
            border:none;
            color:#fff;
        }
    }
    img{
        width: 100%;
    }
}
.corporate{
    background-color:$color-dark;
    padding-top: 60px;
    padding-bottom: 74px;
    .wrapper{
        max-width: 1232px;
    }
    h2{
        border:none;
        @extend .title-h1;
        text-align: center;
        color: #fff;
        padding-bottom: 10px;
    }
    .corpo{
        display: flex;
        justify-content: flex-start;
        flex-wrap:wrap;
        padding-bottom: 57px;
        &-link{
            text-decoration: none;
            background-color: $color-second;
            border-radius: 3px;
            max-width: 327px;
            color:#fff;
            display: inline-flex;
            padding: 0 12px;
            &:hover{
                background-color: $color-dark;
            }
        }
        &>div{
            max-width: 616px;
        }
        &-item{
            position: relative;
            cursor: pointer;
            overflow: hidden;
            a{
                display: block;
            }
            .cont{
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                padding: 50px 40px;
                display: flex;
                flex-direction: column;
                span{
                    display: inline-block;
                    @include bubble(50px, #000);
                    color:#fff;
                    font-style: italic;
                    @include font-size-root(24);
                    font-weight: 900;
                    position: absolute;
                    bottom: 20px;
                    left: 40px;
                }
                p{
                    @include font-size-root(16);
                    position: relative;
                }
                h2{
                    text-align: left;
                    color:$color-dark;
                    font-style: italic;
                    font-weight: 900;
                    @include font-size-root(28);
                    display: inline-block;
                    position: relative;
               
                    &::before{
                        content:'';
                        height:2px;
                        position: absolute;
                        left:0;
                        width:100%;
                        max-width: 285px;
                        bottom:-1px;
                        background-color:#000;
                    }
                }
                .field--name-field-corporate-bloc-text,
                .field--name-field-corporate-bloc-text-par{
                    max-width: 290px;
                    display: inline-block;
                }
                &::before{
                    content:'';
                    display: block;
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    width:100%;
                    margin-left:-100%;
                    background-color:rgba(#000,0.85);
                    transition: margin-left .2s ease-in-out;
                }
            }
            &:nth-child(2n+1){
                .cont{
                    align-items: flex-end;
                    text-align: right;
                    h2{
                        text-align:right;
                    }
                    span {
                        left: auto;
                        right: 40px;
                    }
                }
            }
            &:nth-child(1){
                .cont{
                    h2::before,
                    span{
                        background-color: $color-first;
                    }
                }
            }
            &:nth-child(2){
                .cont{
                    h2::before,
                    span{
                        background-color: $color-second;
                    }
                }
            }
            &:nth-child(3){
                .cont{
                    h2::before,
                    span{
                        background-color: #c7d300;
                    }
                }
            }
            &:nth-child(4){
                .cont{
                    h2::before,
                    span{
                        background-color: #f9d51a;
                    }
                }
            }
            &:hover{
                img{
                    filter: grayscale(100%);
                }
                .cont{
                    &::before{
                        margin-left:0;
                    }
                    h2,
                    p{
                        color:#fff;
                    }
                }
            }
        }
    }
}
.products{
    padding-top: 118px;
    padding-bottom: 74px;
    position: relative;
    overflow: hidden;
    &::after{
        content:$ico-deco;
        font-family: 'leader';
        color:#e9e9e9;
        font-size: 1100px;
        width: 1400px;
        overflow: hidden;
        display: block;
        position: absolute;
        right: -60%;
        bottom: -210px;
        z-index: -1;
    }
    &>.wrapper{
        max-width: 1268px;
        &>h2{
            padding: 0 17px;
            padding-bottom: 43px;
            border:none;
            @extend .title-h1;
            @include font-size-root(28);
            display: flex;
            align-items: center;
            strong{
                padding-left: 8px;
            }
            i{
                color:$color-second;
                margin-right: 12px;
                &::before{
                    @include font-size-root(44);
                }
            }
        }
    }
    .listprod{
        flex-wrap: wrap;
        max-width: 1268px;
        .btn-readmore{
            color:$color-second;
            &::before{
                background-color:$color-second;
            }
        }
        .teaserprod{
            margin-bottom: 48px;
        }
    }
}
.reassurance{
    padding-top: 90px;
    padding-bottom: 74px;
    position: relative;
    overflow: hidden;
    background-color: #191919;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 510px;
    h2{
        border:none;
        @extend .title-h1;
        text-align: center;
        color: #fff;
        padding-bottom: 10px;
        @include font-size-root(28);
    }
    .field--name-field-reassurance,
    .field--name-field-reassurance-paragraph{
        display: flex;
        color:#fff;
        max-width: 1240px;
        @include centered-auto;
        justify-content: space-between;
        &>.field--item{
            padding-top: 100px;
            max-width: 270px;
        }
        .field--name-field-bloc-reassurance-title,
        .field--name-field-bloc-reassurance-title-paragraph {
            @include font-size-root(24);
            text-transform: uppercase;
            letter-spacing: 0.01em;
            padding-bottom: 40px;
            position: relative;
            color:#fff;
            a{
                color:#fff;
                text-decoration: none;
            }
            p{
                position: relative;
                z-index: 1;
            }
            strong{
                font-style: italic;
                font-weight: 900;
            }
            &::after{
                content:$ico-deco;
                left: -22px;
                font-family: 'leader';
                position: absolute;
                color: #f39200;
                bottom: 28px;
                font-size: 107px;
                z-index: 0;
            }
            &::before{
                content:'';
                height:3px;
                width:170px;
                background-color: $color-second;
                position: absolute;
                bottom:21px;
                left:0;
            }
        }
        .field--name-field-bloc-reassurance-text,
        .field--name-field-bloc-reassurance-text-par{
            p{
                @include font-size-root(16);
                letter-spacing: 0.03em;
            }
        }
    }
}
.news{
    max-width: 864px;
    @include centered-auto;
    padding-top: 64px;
    h2{
        border:none;
        @extend .title-h1;
        padding-bottom: 10px;
        @include font-size-root(26);
        margin: 0;
        padding: 0;
    }
    &-head{
        display: flex;
        align-items: center;
        padding-bottom: 30px;
    }
    .ico-deco{
        color:$color-second;
        margin-right: 12px;
        &::before{
            @include font-size-root(44);
        }
    }
    .allnews{
        margin-left:auto;
    }
    .bx-wrapper{
        @include centered-auto;
        margin-bottom: 0;
        .bx-pager{
            bottom: 55px;
            padding-top: 0;
        }
    }
}
.scrollnews{
    .item{
        width:282px;
        
    }
    .expertise-teaser_illus{
        background-color: #fff;
    }
    .homecont{
        padding: 20px 30px;
        .tags{
            @include font-size-root(13);
            font-weight: 900;
            font-style: italic;
            letter-spacing: 0.01em;
            text-decoration: none;
            text-transform: uppercase;
            padding-bottom: 12px;
            display: block;
        }
        .expertise-teaser_titlehome{
            @include font-size-root(21);
            line-height: 23px;
            font-weight: 700;
            font-style: italic;
            letter-spacing: 0.01em;
            text-decoration: none;
            text-transform: uppercase;
            height: 67px;
            display: block;
            overflow: hidden;
        }
    }
}
.homesocial{
    max-width: 1020px;
    @include centered-auto;
    display: flex;
    flex-wrap: wrap;
    padding-right: 78px;
    padding-bottom: 60px;
    align-items: flex-start;
    .links{
        width: 78px;
        padding-top: 34px;
    }
    .feed{
        width: 315px;
        padding-right: 33px;
    }
    .feed{
        &-post{
            background-color: #fff;
            padding: 31px 28px;
            border:1px solid $color-second;
            max-height: 313px;
            overflow: hidden;
            overflow-y: scroll;
            @include scrollstyle;
            a{
                text-decoration: none;
                display: block;
                text-align: left;
            }
            .media{
                padding-bottom: 12px;
            }
            .media + p{
                @extend .p;
                padding-bottom: 12px;
                text-align: left;
                @include font-size-root(16);
                br + br {
                    display: none;
                }
                
            }
            &_title{
                @include font-size-root(20);
                line-height: 21px;
                color:$color-dark;
                font-weight: bold;
                letter-spacing: -0.005em;
                text-decoration: none;
                font-family: Arial, Helvetica, sans-serif;
            }
            &_date{
                @include font-size-root(14);
                line-height: 21px;
                color:#9c9c9c;
                text-decoration: none;
                font-family: Arial, Helvetica, sans-serif;
            }
        }
        &-title{
            color:$color-second;
            @include font-size-root(19);
            font-weight:900;
            text-transform: uppercase;
            font-style: italic;
            padding-bottom: 6px;
        }
    }
    [data-lity]{
        flex-grow: 1;
        position: relative;
        display: block;
        max-width:549px;
        width:549px;
        height: 316px;
        .video-img{
            height:316px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-top: 29px;
            position:relative;
            img{
                width: 100%;
                height: auto;
            }
            &::before{
                content:'';
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                display:block;
                background-color:rgba($color-dark,0.4);
            }
        }
        .ico-video{
            &::before{
                @include font-size-root(50);
            }
            height: 50px;
            width:50px;
            position: absolute;
            top:50%;
            left:50%;
            margin-top:-25px;
            margin-left: -25px;
            color:#fff;
            z-index: 1;
        }
    }
    .full{
        width:100%;
        padding-top: 22px;
        text-align: right;
    }
    .allnews{
        display: inline-flex;
        .ico-youtube{
            color:#ce1312;
            margin-left: 0;
            margin-right: 12px;
            &::before{
                @include font-size-root(22);
            }
        }
    }
    .social-link{
        height: 40px;
        width: 40px;
        display: inline-block;
        line-height: 40px;
        text-align: center;
        margin-bottom: 30px;
        i{
            &::before{
                @include font-size-root(23);
            }
        }
    }
    .field--name-field-social-link-de{
        height: 40px;
        width: 40px;
        display: inline-block;
        line-height: 40px;
        text-align: center;
        margin-bottom: 30px;

        a{
            color: transparent;
            &::before{
                background-image: url("../images/picto-fb.png");
                width: 40px;
                background-repeat: no-repeat;
                content: '';
                display: block;
                right: 0;
                background-position-y: center;
                max-height: 40px;
                height: 100%;
            }
        }
    }
}

.contacthome{
    background-color: #fff;
    padding: 40px 0;

    .wrapper{
        max-width: 864px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div{
            width:414px; 
        }
    }
    .ico-deco{
        color:$color-second;
        &::before{
            @include font-size-root(50);
        }
    }
    p{
        max-width: 320px;
        @extend .p;
    }
    .title-h1{
        @include font-size-root(46);
        padding-top: 5px;
        padding-bottom: 20px;
        line-height: 48px;
    }
    .form-submit{
        min-width:10px;
    }
}
.body{
    max-width: 680px;
    @include centered-auto;
    padding: 60px 0;
    .field--name-body {
        p:first-child{
            font-weight: 300;
        }
        hr{
            background-color: transparent;
            border:none;
        }
        div.p{
            display: none;
        }
    }
    .more{
        @extend .btn-readmore;
        display: inline-flex;
        background: none;
        border:none;
        outline:0;
        align-items: center;
        .closetxt {
            display: none;
        }
        &::before{
            display: inline-block;
            position: static;
            width:14px;
            height:3px;
            margin-right: 10px;
        }
        &.active {
            span:first-child {
                display: none;
            }
            .closetxt {
                display: inline-block;
            }
        }
    }
    .showing{
        text-align: center;
        &+.p{
            padding-top: 20px;
        }
    }
}
