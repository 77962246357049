@import 'mixins';

.text-align-right{
    text-align: right;
}
.text-align-justify{
    text-align: justify;
    display: flex;
    justify-content: space-between;
}

.title-h1{
    color:$color-dark;
    @include font-size-root(37);
    font-family: $fontfam;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    strong{
        font-weight: 900;
        font-style: italic;
    }
}

.title-h2{
    @include font-size-root(19);
    font-weight: 900;
    font-style: italic;
    border:none;
    color:$color-dark;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.title-h3{
    @include font-size-root(15);
    font-weight: 900;
    font-style: italic;
    border:none;
    color:$color-dark;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.title-inter{
    @include font-size-root(28);
    font-weight: 900;
    font-style: italic;
    border:none;
    color:$color-dark;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.p{
    @include font-size-root(16);
    color:$color-dark;
    padding-bottom: 30px;
    line-height: 25px;
    letter-spacing: 0.03em;
    text-align: justify;
}

.body {
    p{
        @extend .p;
        &:first-child{
            font-weight: 900;
        }
        &:last-child{
            padding-bottom: 0;
        }
    }
    ul{
        padding-bottom: 15px;
    }
    ul li{
        @extend .p;
        padding-bottom: 10px;
        position: relative;
        &::before{
            content:'-';
            display: inline-block;
            padding-right: 4px;
        }
        br:last-child{
            display: none;
        }
    }
    ol{
        padding-bottom: 15px;
        padding-left: 20px;
    }
    ol li{
        list-style-type: decimal;
        @extend .p;
        padding-bottom: 10px;
        position: relative;
    }
    .text-align-center{
        text-align: center;
    }
    h2{
        @extend .title-h2;
    }
    h3{
        @extend .title-h3;
    }
    h4{
        @extend .title-h3;
        @include font-size-root(13);
    }
    h5{
        text-transform:uppercase;
        font-weight: 700;
        padding-bottom: 10px;
    }
    h6{
        text-transform:uppercase;
        font-weight: 700;
        text-decoration:underline;
        padding-bottom: 10px;
    }
    blockquote{
        margin-bottom:30px;
        padding: 10px 50px;
        max-width: 80%;
        border-left:4px solid $color-first;
        p{
            font-weight:900;
        }
        
    }
}