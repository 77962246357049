#gl-comparator{
    max-width: 1150px;
    @include centered-auto;
    padding-bottom: 110px;
    h1{
        @extend .title-h1;
        text-align: center;
    }
    .gl-characteristic,
    .gl-product-list{
        display: flex;
        &>div{
            width: 328px;
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    .gl-product-list{
        padding-top: 90px;
    }
    div[class*="-label"]{
        width:170px;
        padding: 0
    }
    [class*="gl-product-action-"]{
        height: 44px;
        width: 44px;
        min-width: 44px;
        padding: 0;
        border:1px solid $color-dark;
        line-height: 42px;
        text-align: center;
        display: block;
        @include centered-auto;
        border-radius: 22px;
        margin-bottom: 24px;
        span{
            @include close-button ($size: 17px, $thick: 1px, $color: $color-dark);
            vertical-align: middle;
            font-size: 0;
            height: 43px;
            width: 43px;
        }
        &:hover {
            span::after,
            span::before{
                background-color: #fff;
            }
        }
        
    }
    .gl-product-field-image{
        background: #fff;
        width: 100%;
        height:247px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 24px 71px -34px rgba(0,0,0,0.35);
        margin-bottom: 30px;
        img{
            max-width: 292px;
            max-height: 247px;
        } 
    }
    .gl-product-field-title{
        h2{
            @extend .title-h2;
            a{
                text-decoration: none;
            }
        }
    }
    .gl-product-field-description{
        @include font-size-root(16);
        font-weight: 300;
        letter-spacing: 0.03em;
    }
    .gl-characteristics-group{
        padding-top: 25px;
        &:first-child{
            padding-top: 0;
        }
    }
    .gl-characteristics-group-list{
        padding-top: 70px;
        border-bottom: 1px solid #c1c1c1;
        h2{
            @extend .title-h2;
            padding-bottom: 20px;
        }
    }
    .gl-characteristic{
        border-top: 1px solid #c1c1c1;
        padding: 25px 0;
        &-label{
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 0.01em;
            font-style: italic;
            strong{
                font-weight: 900;
            }
        }
        &-value{
            @include font-size-root(16);
        }
    }
}


.comparateur-de-produit,
.product-comparator{
    .site-content{
        position: relative;
        overflow: hidden;
        &::after{
            content:$ico-deco;
            font-family: 'leader';
            color:#e9e9e9;
            font-size: 1500px;
            width: 1400px;
            overflow: hidden;
            height: 2000px;
            display: block;
            position: absolute;
            right: -30%;
            top: 50%;
            margin-top: -1000px;
            z-index: -1;
            transform: rotate(-90deg);
        }
    }
}
