@charset "UTF-8";

/*====================
/1 Reset et mixins
======================*/
@import "reset";
@import 'mixins';

/*====================
/2 variables mixins et fonts
======================*/
@import 'variables';
@import "utils";
@import 'fonts';



/*====================
/3 utilitaires du framework
======================*/
@import "forms";
@import "typos";
@import "plugins/bxslider";
@import "plugins/lity";
@import "plugins/lightboxgallery";
// @import "plugins/select2";
// @import "plugins/multiselect";
// @import "plugins/selectize";
// @import "plugins/airdatepicker";
// @import "plugins/pagination";
// @import "plugins/flexslider";
@import "plugins/hamburgers/hamburgers";
// @import "plugins/flag-icon/flag-icon";

/*====================
/4 theme du site
======================*/
@import 'icons';
@import "theme";


/*====================
/5 utilitaires responsive
======================*/
@import "responsive";

/*====================
/6 theme responsive
======================*/
@import "themeresponsive";


/* fix font */
body,
[class^="grid-"],
.v-align-wrap {
  font-family:$fontfam;
  @include font-size-root($fontsize);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body{
  &.rollme {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1200px;
    height: 1200px;
    margin: -600px 0 0 -600px;
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
  }
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}
