$page: 1360px;
$fontfam: "museo-sans",sans-serif;
$fontsize: 14;
$gridmargin: 30px;


//breakpoint
$desk: 1024px;
$phab: 767px;
$palm: 465px;


//color palet
$color-first: #d00918;
$color-second: #f39200;
$color-third: #000;

$color-dark : #1c1c1b;
$color-darkgrey: #666;
$color-lightgrey: #dadada;
$color-body: #f0f1f3;

$transitionbtn: color .5s ease-in-out, background-color .5s ease-in-out;

$ico-youtube: "\e910";
$ico-smallarrow: "\e90f";
$ico-smalldoublearrow: "\e90f\e90f";
$ico-fbplain: "\e90b";
$ico-mail: "\e90c";
$ico-plus: "\e90d";
$ico-print: "\e90e";
$ico-file: "\e90a";
$ico-video: "\e909";
$ico-arrowTB: "\e900";
$ico-deco: "\e901";
$ico-facebook: "\e902";
$ico-instagram: "\e903";
$ico-linkedin: "\e904";
$ico-magnify: "\e905";
$ico-more: "\e906";
$ico-pinterest: "\e907";
$ico-twitter: "\e908";



