.page-node-type-page{
    .page.full{
        max-width: 850px;
        @include centered-auto;
        .field--name-body{
            @extend .body;
            .btn-submit{
                display: inline-flex;
                text-decoration: none;
                color:#fff;
            }
        }
    }
}


.region-content .sitemap{
    @extend .body;
    max-width: 860px;
    @include centered-auto;
    h1{
        @extend .title-h1;
    }
    ul{
        li{
            a{
                &:hover{
                    color:$color-first;
                }
            }
        }
    }
    .content>a{
        display: inline-block;
        @extend .p;
    }
}
.page-zonecontent{
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    margin-bottom:60px;
}

body.merci {
    .title-h1 {
        text-align: center;
        padding-top: 40px;
        font-weight:  900;
        font-style: italic;
        color: $color-second;
    }
    .page.full .field--name-body{
        padding-top: 10px;
    }
}

body.qui-sommes-nous {
    #breadcrumb ol li:last-child a, #breadcrumb ol li:last-child a span {
        color: $color-second;
    }
}