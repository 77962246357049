.search-results{
    max-width: 951px;
    @include centered-auto;
    padding: 20px 0;
    .no-results{
        h3{
            text-align: center;
            color:$color-dark;
            text-transform: uppercase;
            padding: 30px 0;
        }
    }
}
.search-form{
    max-width: 400px;
    @include centered-auto;
    padding: 20px;
    .search-box__button {
        width:100%;
    }
}
.search-results{
    ol {
        display: flex;
        flex-wrap: wrap;
    }
    .teaserprod{
      position: relative;
    }
    .teaserprod-img{
        flex-direction: column;
        padding: 20px;
        h3{
            text-decoration: none;
            @extend .title-h3;
        }
    }
}
.search-form{
  & ~ h2{
    max-width: 951px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    @extend .title-h2;
  }
}

.page-search {
  @include centered-auto;  
  max-width: $page;
  
  .btn-close,
  .control-label,
  .btn-open-search {
    display: none;
  }

  .header-search {
    padding-bottom: 50px;
    padding-left: 22px;
    padding-right: 22px;

    form {

      .form--inline {
        display: flex;
        flex-wrap: wrap;
      }

    }

  }

  .pager-nav {
    padding-top: 30px;
    .pager__item{
      .btn-submit{
        min-width: 50px;
      }
    }
  }

  .teaserprod-img {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    padding: 18px;
    
    > div {
      width:100%;
      max-width: 100%;
    }

    a {
      text-decoration: none;
    }

    .views-field-name,
    .views-field-title {
      font-weight: bold;
      padding-bottom: 10px;
      text-transform: uppercase;
    }
    .content-type-label{
      padding: 2px 8px;
      position: absolute;
      text-align: center;
      display: inline-block;
      right: -1px;
      bottom: -1px;
      width: auto;
      box-shadow: rgba(50, 50, 50, 0.4) 0 1px 3px 0;
      z-index: 1;
      background: #f39200;
      color: #FFF;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
