.familly{
    background-color: #e5e7ea;
    position: relative;
    top: -1px;
    margin-bottom: -1px;
    .wrapper{
        display: flex;
        &>.views-element-container:first-child>a{
            position: relative;
            color:#fff;
            background-color: #000;
            border-color:#000;
            z-index:3;
            span{
                color:#fff;
            }
            &[href*="/materiel-atex/"] {
                h2 {
                    color: $color-dark;
                }
            }
            &::after{
                content:'';
                border-left:13px solid transparent;
                border-left-color:inherit;
                border-bottom:57px solid transparent;
                position: absolute;
                right:-13px;
                top:0;
                height:0;
                width:0;
            }
        }
        &>.views-element-container:nth-child(2)>.active+a:nth-child(2){
            padding-left:35px;
            position: relative;
            &::after{
                content:'';
                border-left:13px solid transparent;
                border-left-color:inherit;
                border-bottom:57px solid transparent;
                position: absolute;
                right:-13px;
                top:0;
                height:0;
                width:0;

            }
        }
        &>.views-element-container:nth-child(2)>.active+a{
            position: relative;
            background-color: rgba(0,0,0,0.5);
            border-color: rgba(0,0,0,0.5);
            &::after{
                content:'';
                border-left:13px solid transparent;
                border-left-color:inherit;
                border-bottom:57px solid transparent;
                position: absolute;
                right:-13px;
                top:0;
                height:0;
                width:0;

            }
            &::before{
                content:'';
                border-right:13px solid transparent;
                border-right-color:inherit;
                border-top:57px solid transparent;
                position: absolute;
                left:-13px;
                top:0;
                height:0;
                width:0;

            }
        }
    }
    .views-element-container{
        display: flex;
        a{
            height: 57px;
            display: flex;
            align-items: center;
            padding: 0 25px;
            font-weight: 700;
            color:$color-dark;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            line-height: 16px;
            @include font-size-root(13);
            h2{
                font-weight: 700;
                color:$color-dark;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                line-height: 16px;
                @include font-size-root(13);
                border:none;
                padding: 0;
                margin:0;
            }
        }
    }
}

.product-illus{
    max-width: 1150px;
    @include centered-auto;
    justify-content: space-between;
    padding-top: 20px; 
    padding-bottom: 40px;
    .bx-wrapper{
        margin-bottom: 0;
        &+.bx-wrapper{
            @include centered-auto;
        }
        [data-slide-index]{
            display: flex;
            @include centered-auto;
            width:80px;
            height: 80px;
            background-color: #fff;
            align-items: center;
            justify-content: center;
        }
    }
    .slider-main{
        text-align: center;
        .slide{
            padding: 30px;
            height:100%;
            min-height: 415px;
            img{
                max-height: 370px;
                width:auto !important;
            }
        }
    }
    .slide{
        height: 430px;
        display:flex;
        align-items: center;
        justify-content:center;
    }
    &>div{
        width:540px;
    }
    h1{
        @extend .title-h1;
        font-weight: 900;
        font-style: italic;
        .light{
            font-weight: 300;
            font-style: normal;
        }
    }
    &_imgs{
        position: relative;
        height: 510px;
        overflow: hidden;
    }
    &_bg{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: absolute;
        top:0;
        left:0;
        right:0;
        min-height: 430px;
        z-index: 0;
    }
    &_slider{
        position: relative;
        z-index: 1;
        .bx-wrapper .bx-controls-direction a {
            color: $color-dark;
            border-color: $color-dark;
            &::before {
                color: $color-dark;
            }
        }
    }
}
.arguments{
    padding-bottom: 30px;
    padding-top: 25px;
}
.argument{
    background-color: #fff;
    margin-top: 3px;

    &-title{
        padding: 0 25px;
        height:45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 900;
        text-transform: uppercase;
        font-style: italic;
        cursor: pointer;
        span{
            &::before{
                content: '+';
            }
        }
        &.open{
            span{
                &::before{
                    content: '-';
                }
            }
        }
    }
    &-cont{
        padding: 8px 25px 20px;
        display: none;

        ul {
            li {
                padding-bottom: 10px;
                position: relative;
                &::before{
                    content:'-';
                    display: inline-block;
                    padding-right: 4px;
                }
            }
        }
    }
}
.product-illus{
    .actions{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &>div{
            width:50%;
        }
    }
}
[class*="gl-product-action-"]{
    @extend .btn-submit;
    text-decoration:none !important;
    border:1px solid #000;
    background-color: transparent;
    height:56px;
    &:hover{
        color:#fff;
    }
    &.ajax-loading{
        background: #fff url(../images/loading.svg) center center no-repeat;
        background-size: 30px auto;
        span{
            opacity: 0;
        }
    }
}
.tabs{
   background-color: #fff;
   &>.wrapper:first-child{
       padding: 34px 19px 0;
       display: flex;
       justify-content: space-between;
       flex-grow: 1;
   }
   .tab-content{
        background-color: #f0f1f3;
        .wrapper>div{
           padding: 40px 60px;
           display: none;
        }
        .wrapper>.active{
           display: block;
        }
        .wrapper>.products{
           padding-left: 0;
           padding-right: 0;
        }
        .carac .flex{
           display: flex;
           flex-wrap: wrap;
           justify-content: space-between;
           flex-flow: 1;
           .carac-img{
                width: 644px;
                padding-bottom: 20px;
                .bx-wrapper{
                    margin-bottom: 18px;
                }
                [data-slide-index]{
                    border:2px solid #000;
                    display: block;
                    width:64px;
                    position: relative;
                    img{
                        display: block;
                        
                    }
                    &::before{
                        content:'';
                        position: absolute;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                        display: block;
                        box-shadow: inset 2px 2px 1px 0px rgba(255,255,255,1);
                        background-color: rgba(#1d1d1b, 0.5);
                    }
                }
                .active{
                    &::before{
                        background-color: transparent;
                    }
                }
           }
           .carac-cont{
               width: 500px;
           }
           .videos{
               width: 100%;
           }
        }
        h2{
           @extend .title-h3;
        }
        h3{
            @extend .title-h3;
        }
    }
    .tab{
        margin: 0 4px;
        padding-top: 4px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #e5e7ea;
        flex-grow: 1;
        font-weight: 700;
        color: $color-dark;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        cursor: pointer;
        &:hover{
            background-color: #c3c8ce;
        }       
        &.active{
            color:#fff;
            background-color: #000;
            &:hover{
                background-color: darken($color-first, 5%);
            }
        }
    }
}
.attachements{
    .file-icon{
        @extend .ico-file;
        &::before{
            font-family: 'leader';
        }
        padding-right:8px;
    }
    table{
        td{
            border-top: 1px solid #c1c1c1;
            padding: 15px 0;
            @include font-size-root(16);
        }
        th{
            padding: 15px 0;
            text-transform: uppercase;
        }
    }
}
.rating{
    position: relative;
    display: inline-block;
    &>div{
        white-space: nowrap;
        span{
            display: inline-block;
        }
    }
    div+div{
        position: absolute;
        top:0;
        left:0;
        overflow: hidden;
    }
}
.product-illus_note{
    display: inline-block;
    padding-left: 8px;
}
.testimony{
    padding-top:35px;
    padding-bottom: 40px;
}

.videos{
    padding-top: 49px;
    .video{
        max-width: 589px;
        a{
            display: flex;
            background-color:#fff;
            align-items: flex-start;
            text-decoration: none;
        }
        &-img{
            position: relative;
            height: 180px;
            width:45%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height:180px;
                width: auto;
                max-width: 99999em;
            }
            .ico-video{
                &::before{
                    @include font-size-root(50);
                }
                height: 50px;
                width:50px;
                position: absolute;
                top:50%;
                left:50%;
                margin-top:-25px;
                margin-left: -25px;
                color:#fff;
                z-index: 1;
            }
            &::after{
                content:'';
                position: absolute;
                display: block;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background-color: rgba(#1d1d1b, 0.7);
            }
        }
        &-cont{
            width:55%;
            text-decoration: none;
            padding: 20px 30px;
            @include font-size-root(16);
            text-decoration: none;
            letter-spacing: 0.03em;
            h3{
                @extend .title-h3;
            }
        }
    }
}
.tabs .fiche{
    h3{
        @extend .title-h2;
        @include font-size-root(25);
    }
    .charac{
        padding-bottom: 35px;
    }
    dl{
        display: flex;
        flex-wrap: wrap;
        vertical-align: center;
        dt,
        dd{
            @include font-size-root(16);
            border-top:1px solid #c1c1c1;
            padding: 25px 0;
        }
        dt{
            flex-grow: 0;
            width: 230px;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: .01em;
            font-style: italic;
        }
        dd{
            flex-grow: 1;
            max-width: calc(100% - 230px);
            width: calc(100% - 230px);
        }
    }
}

.listprod{
    display: flex;
    max-width: 1264px;
    @include centered-auto;
}
.teaserprod{
    width:283px;
    margin: 0 17px;
    margin-bottom: 20px;
    &-img{
        background-color:#fff;
        height: 247px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 33px 80px -39px rgba(0,0,0,0.48);
        position: relative;
        overflow: hidden;
        &:hover{
            .desc{
                margin-top: 0;
            }
        }
        img{
            max-height: 207px;
            max-width: 263px;
        }
        .desc{
            width:100%;
            height: 100%;
            top:0;
            left:0;
            position: absolute;
            color:#fff;
            background-color: rgba($color-dark, 0.95);
            text-decoration: none;
            padding: 35px 24px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @include font-size-root(15);
            line-height: 19px;
            letter-spacing: 0.01em;
            font-weight: 500;
            margin-top: -100%;
            transition: margin-top .3s ease-in-out;
            .label{
                text-transform: uppercase;
                @include font-size-root(14);
                letter-spacing: 0.03em;
                font-style: italic;
                padding-bottom: 6px;
                font-weight: 300;
            }
        }
    }
    &-cont{
        padding: 10px;
        padding-top: 15px;
        h2, h3{
            @extend .title-h3;
            padding-bottom: 8px;
            a{
                text-decoration: none;
            }
        }
    }
}

.testimony{
    max-width: 1240px;
    @include centered-auto;
    &-title{
        @extend .title-inter;
        .light{
            font-weight: 300;
            font-style: normal;
        }
    }
    &-item{
        background-color: #fff;
        padding: 32px 44px;
        margin-bottom: 14px;
        .line{
            line-height: 25px;
            @include font-size-root(16);
            [itemprop="name"] {
                font-weight: 700;
                font-style: italic;
            }
        }
        .rating{
            margin-right: 10px;
            &>div {
                span img{
                    vertical-align: middle;
                }
            }
        }
    }
    &-description{
        padding-top: 14px;
        font-weight: 700;
        line-height: 23px;
        @include font-size-root(16);
        letter-spacing: 0.01em;
        color:$color-dark;
        text-transform: uppercase;
        font-style: italic;
        
    }
}

.page-products{
    .bloc-expertises{
        max-width: 1270px;
        @include centered-auto;
        margin-top: 40px;
        clear:both;
        .views-rows>div{
            margin: 0 18px;
        }
    }
    #breadcrumb{
        background-color: #fff;
        max-width: 1240px;
        padding-left: 40px;
        padding-right: 40px;
        @include centered-auto;
    }
    .taxo-products{
        max-width: 1240px;
        @include centered-auto;
        background-color: #fff;
        padding: 0 22px;
        h1>div{
            font-weight: 900;
            font-style: italic;
            .light{
                font-weight: 300;
                font-style: normal;
                color:$color-dark;
            }
        }
        p.center {
            text-align: center;
            padding-bottom: 40px;
            clear: both;
        }
      .flslide{
        width: 374px;
        height: 225px;
        max-height: 225px;
        overflow: hidden;
      }
    }
    .taxo-top{
        max-width: 1240px;
        @include centered-auto;
        display: flex;
        align-items: center;
        height:48px;
        margin-bottom: 40px;
        justify-content: space-between;
        &>div{
            text-transform: uppercase;
            @include font-size-root(18);
            letter-spacing: 0.03em;
            strong{
                font-style: italic;
                font-weight: 900;
            }
        }
        .view-filters{
            margin-left: 0;
            .form--inline{
                display: flex;
            }
            .form-actions,
            .control-label{
                display: none;
            }
            .dropdown{
                max-width: 180px;
            }
            .form-item{
                display: block;
                padding: 0;
                padding-left: 8px;
            }
        }
    }
    .views-element-container ~ {
        .wrap-taxo{
            position: relative;
            top:-100px;
            margin-bottom: -100px;
            z-index: 2;
        }
    }
}
.taxo-bgd {
    display: flex;
    min-height:300px;
    max-height:530px;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    z-index: 0;
    overflow: hidden;
    img {
        width: 100%;
    }
    
}
.taxo-products{
    h1{
        @extend .title-h1;
        text-align: center;
        max-width: 980px;
        @include centered-auto;
    }
    .field--name-description{
        @extend .body;
        max-width: 980px;
        padding: 0;

        img[style*='float:left;'] {
            margin-right: 22px;
            margin-bottom: 10px; 
        }
        p:first-child{
            font-weight: 300;
            letter-spacing: 0.03em;
            padding-bottom: 30px;
        }
        .p {
            padding-bottom: 0;
            p{
                padding-bottom: 30px;
            }
        }
        p{
            letter-spacing: 0.03em;
            @extend .p;
        }
        div.p{
            display:none;
        }
        p:first-child{
            span.center:last-child{
                padding-top: 20px;
                display: block;
            }
        }
        [data-lity]{
            position: relative;
            display: block;
            .video-img{
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .ico-video{
                &::before{
                    @include font-size-root(50);
                }
                height: 50px;
                width:50px;
                position: absolute;
                top:50%;
                left:50%;
                margin-top:-25px;
                margin-left: -25px;
                color:#fff;
                z-index: 1;
            }
        }
        span.flleft{
            margin-right:15px;
            margin-bottom: 10px;
        }
        span.flright{
            margin-left:15px;
            margin-bottom: 10px;
        }
    }
    .flright {
        margin-left: 22px;
    }
    .flleft {
        margin-right: 22px;
    }
}

.page-products{
    position: relative;
    overflow: hidden;
    &::after{
        content:$ico-deco;
        font-family: 'leader';
        color:#e9e9e9;
        font-size: 1500px;
        width: 1400px;
        overflow: hidden;
        height: 2000px;
        display: block;
        position: absolute;
        right: -30%;
        top: 50%;
        margin-top: -1000px;
        z-index: -1;
        transform: rotate(-90deg);
    }
    .dropdown{
        background-color: transparent;
        height:49px;
        &::after{
            border:none;
            content: $ico-arrowTB;
            font-family: 'leader';
            font-size: 13px;
            right: 27px;
            margin-top: -7px;
        }
        select{
            background-color: transparent;
            line-height: 49px;
            height:49px;
            padding-left: 18px;
            padding-right: 42px;
            width:auto;
            min-width: 80px;
        }
    }
}


.livreblanc{
    background-color:#fff;
    .wrapper{
        position: relative;
        padding: 41px 36px;
    }
    &-cont{
        padding: 41px 36px;
        padding-left: 500px;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        align-items: center; 
        &>div{
            max-width: 720px;
        }
        &_title{
            font-weight: 900;
            font-style: italic;
            @include font-size-root(25);
            text-transform: uppercase;
        }
        &_subtitle{
            font-weight: 500;
            @include font-size-root(25);
            text-transform: uppercase;
            color:$color-first;
            padding-bottom: 8px;
        }
        &_body{
            @extend .body;
            padding: 0;
            text-align: left;
            line-height: initial;
            p:first-child{
                font-weight: 300;
            }
        }
        &_button{
            padding-top: 8px;
            .btn-submit{
                display: inline-flex;
                color:#fff;
                text-decoration: none;
            }
        }
    }
}

.page-node-type-product{
    .block-addtoany-block {
        text-align: center;
        padding-bottom: 40px;
    }
}

.field--name-field-product-caracterist-desc {
    p {
        padding-bottom: 12px;
    }
    img[style*='float:left;'] {
        margin-right: 22px;
        margin-bottom: 10px; 
    }
    ul {
        clear: left;
        padding-bottom: 15px;
        padding-top: 10px;
        li{
            
            padding-bottom: 10px;
            position: relative;
            &::before{
                content:'-';
                display: inline-block;
                padding-right: 4px;
            }
            br:last-child{
                display: none;
            }
        }
    }
}

.cross-selling {
    max-width: 1268px;
    margin-left: auto;
    margin-right: auto;
    h2 {
        padding-left: 17px;
        padding-right: 17px;
    }
    .listprod{
        flex-wrap: wrap;
        max-width: 1268px;
    }
    .testimony-title {
        font-weight: 300;
        font-style: normal;
        strong {
            font-style: italic;
            font-weight: 900;
        }
    }
}

.ref .field--name-field-product-reference  {
    @extend .body;
    max-width: 9999em;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    p:first-child {
        @extend .p;
        font-weight: 400;
    }
}

.carac {
    .videos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &.video-solo {
            justify-content: center;
        }
        .video {
            margin-bottom: 30px;
        }
    }
}
