body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dt, dd, ol, ul, li, form, fieldset, legend, table, th, td, caption, hr {margin: 0; padding: 0;}
body {-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;}
table {border-collapse: collapse; border-spacing: 0; width: 100%;}
fieldset, img {border: 0;}
img {font-style: italic; vertical-align:bottom;}
ol, ul {list-style: none;}
caption, th {text-align: left;}
h1, h2, h3, h4, h5, h6 {font-size: 100%; font-weight: normal;}
abbr[title], acronym[title], dfn[title] {cursor: help; border-bottom: 1px dotted;}
a {text-decoration: none; background:transparent;}
pre {overflow: auto;}
pre, code {font-family: monospace;}
b, strong {font-weight: bold;}
sub, sup {font-size: 65%; line-height: 0; position: relative; vertical-align: baseline;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
small {font-size: 80%;}
button, input, select, option, optgroup, textarea {font: inherit; color: inherit; margin:0;}
[type="submit"], [type="button"], [type="reset"], button {cursor:pointer; border:0; overflow:visible; line-height: normal;}
button::-moz-focus-inner, input::-moz-focus-inner {border: 0; padding: 0;}
input {line-height: normal;}
[type="checkbox"], [type="radio"] {padding: 0;}
textarea {overflow: auto; resize:none;}

/* HTML5 */
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }
audio, canvas, progress, video {display: inline-block; vertical-align: baseline;}

/* Box model */
html{
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}
