
/*====================
/ Responsive (Mobiles, tablettes, ...)
======================*/

/* Ecrans HDPI
=================*/
@media (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (-webkit-min-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi),
  (min-resolution: 1.5dppx) {
    /* Images @2x */
}

.touchevents{
  .you-are, .lang{
    width:101px;

    .expanded.dropdown:hover{
      &>ul{
        left: -99999em;
      }
    }
    .expanded.dropdown [data-toggle="dropdown"].open+ul{
      left: auto;
      right: 0;
    }
  }
  .navigation .region-navigation .menu li:hover{
    .children{
      position: static;
      display:none;
    }
  }
  .navigation .region-navigation .menu li.is-open{
    .children{
      position: static;
      display: block;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .touchevents .navigation .region-navigation .menu li.touch-open .children {
    position: absolute;
    right: 0;
    left: 0;
    display: block;
  }
}
@media only screen and (max-width: 1264px) {
  .corporate .corpo>div{
    width:50%;
  }
  .products .listprod{
    max-width: 958px;
  }
  .reassurance .field--name-field-reassurance{
    padding-left:12px;
    padding-right:12px;
  }
  .reassurance .field--name-field-reassurance>.field--item{
    max-width: 230px;
  }
}

@media only screen and (min-width: $desk + 1) {
  .btn-submenu,
  .hamburger{
    display: none;
    outline:0;
  }
}

@media only screen and (max-width: 1350px) {
  .page-products .taxo-top{
      padding-left: 17px;
      padding-right: 17px;
  }
  #breadcrumb ol{
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1240px) {
  .bloc-expertises .view-content{
    max-width: 980px;
    @include centered-auto;
  }

}
@media only screen and (max-width: 1100px) {
  .livreblanc{
    &-cont{
      padding: 30px 30px 30px 35%;
      &>div{
        max-width: 600px;
      }
      &_title{
        @include font-size-root(20);
      }
      &_subtitle{
        @include font-size-root(16);
      }
      &_body{
        .field--name-body{
          p{
            padding-bottom: 10px;
            line-height: 20px;
          }
          .livreblanc-cont_button{
            padding-top: 0;
          }
        }
        .livreblanc-cont_button{
          &+p{
            display: none;
          }
        }
      }
      &_body + &_button{
        .btn-submit{
          line-height: 18px;
          @include font-size-root(14);
        }
      }
    }
  }

  .region-navigation .menu>li>a, .region-navigation .menu>li>span>a{
    padding: 0 10px;
  }
}

/* Environnement small screen
==================================================*/
@media only screen and (max-width: $desk) {
  .product-illus .actions{
    display: none;
  }
  .region-header{
    padding: 8px 12px 0;
  }
  #logo{
    max-width: 120px;
    transform: unset;
  }
  header[role=banner]{
    height: 94px;
    .btn-open-search{
      position: absolute;
      top: 50px;
      left: calc(100% - 170px);
    }
  }
  .news-head,
  .contacthome .wrapper,
  .mega-menu .item-list ul, .region-navigation, .region-navigation .menu{
    flex-wrap:wrap;
  }
  .slider-cont{
    width: 100%;
    left:0;
    right:0;
    bottom:0;
    position: relative;
  }
  .slider .pos{
    position: static;
  }
  .footer .inner{
    padding:40px 12px;
  }
  .footer{
    .text-align-justify{
      flex-wrap: wrap;
    }
  }
  .top-menu{
    display: none;
  }
  .navigation{
    display: block;
    position: fixed;
    background-color: #fff;
    top:0;
    right:0;
    width:100%;
    bottom:0;
    z-index: 1000;
    margin-right:-100%;
    transition: margin-right .3s ease-in-out;
  }

  .menu-open{
    .top-menu{
      display: block;
      position: fixed;
      top:0;
      left:50%;
      margin-left: -160px;
      width:320px;
      z-index: 9999;
      nav[role=navigation]{
        justify-content: center;
        flex-wrap: wrap;
        li{
          width: 100%;
        }
      }
    }
    .navigation{
      padding-top: 100px;
      display: block;
      margin-right: 0;
      overflow-y: scroll;
      @include scrollstyle;
      .region-navigation{
        max-width: 380px;
        width:100%;
        justify-content: center;
        .main-menu,
        .menu{
          width:100%;
          justify-content: center;
          li{
            width:100%;
            a{
              text-align: left;
              justify-content: flex-start;
              height:auto;
              padding: 15px 0;
              max-width: 80%;
            }
          }
        }
      }

    }
  }

  // ============================================================
  // hamburger
  .hamburger{
    position: absolute;
    top: 5px;
    right:5px;
    z-index: 9999;
    .hamburger-box {
      height: 20px;
    }
  }

  .adminimal-admin-toolbar{
    .hamburger{
      top:40px;
    }
  }

  // ============================================================
  // Debugage février 2020
  // language-switcher-language-url
  // ============================================================

  .language-switcher-language-url{
    position: absolute;
    top: 50px;
    right: 7px;
    z-index: 100;

    .lang {
      padding-left: 3px;
      margin-left:0;
      border-bottom:0;
      // border: 1px #dadada solid;
      border-left: 2px solid #dadada;
      width: auto!important;

      [class*=" ico-"], [class^=ico-] {
        &:before {
          padding-right: 3px;
        }
      }
      .menu {
        li a {
          padding-right: 5px;
        }
      }
      .dropdown-menu {
        li {
          float: none;
          display: block;
          min-width: 55px!important;
          a {
            padding-left: 5px;
            padding-right: 5px;
          }
          &::before {
            display: none!important;
            right: 0!important;
          }
        }
      }
      //clicactive
      &.clicactive {
        .dropdown-menu {
          left: 0!important;
        }
      }

    }
  }

  // ============================================================
  // Debugage février 2020
  // language-switcher-language-url
  // ============================================================


  // social
  .social{
    position: absolute;
    top: 50px;
    padding-right:0;
    right:180px;
  }
  .site{
    padding-top: 94px;
  }

  .products, .reassurance{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .products>.wrapper>h2{
    flex-wrap: wrap;
  }
  .news h2, .products>.wrapper>h2, .reassurance h2{
    @include font-size-root(20);
  }
  .news .ico-deco::before, .products>.wrapper>h2 i::before{
    @include font-size-root(30);
  }
  .contacthome{
    padding: 18px 17px;
    p:first-child{
      padding-bottom: 5px;
    }
    .title-h1{
      @include font-size-root(20);
      line-height:initial;
    }
    .ico-deco{
      &::before{
        @include font-size-root(30);
      }
    }
  }
  .expertise-teaser{
    max-width: 282px;
    margin-left: auto;
    margin-right: auto;
  }
  .homepage{
    .body{
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 20px;
    }
  }
  .corporate .corpo-item a,
  .corporate .corpo-item a div,
  .corporate .corpo-item .cont{
    height:300px;
    img{
      height:100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .page-products .taxo-top{
    padding-left:17px;
    padding-right:17px;
  }
  .taxo-products h1{
    @include font-size-root(30);
  }
  .navigation .region-navigation .menu li{
    position: relative;
    .btn-submenu{
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 50px;
      background-color:#fff;
      display: flex;
      align-items: center;
      justify-content: center;
      outline:0;
      padding: 0;
      &.is-active{
        .ico-arrowTB{
          position: relative;
          transform: rotate(180deg);
        }
      }
    }
  }
  #block-navigationdroite{
    width: 100%;
  }
  .mega-menu{
    padding: 20px;
  }
  .familly .views-element-container, .familly .wrapper{
    flex-wrap: wrap;
  }
  .top-menu .menu{
    background-color: #fff;
  }
  .cross-expertise .views-element-container{
    width:100%;
  }
  .product-illus .actions{
    display: none;
  }
  .teaserprod-img {
    position: relative;
    .desc {
      display: none;
    }
    &.mobile-active {
      .desc {
        margin-top: 0;
      }
    }
  }

  .familly {
    width: 100%;
    transform:  translate3d(-110%, 0, 0);
    transition: transform .3s ease-in-out;
    background-color: #fff;
    z-index: 999;
    height: auto;
    position: absolute;
    top: 45px;
    border-bottom: 2px solid #ddd;
    .wrapper {
      max-width: 320px;
    }
    &.swipe-in {
      transform:  translate3d(0, 0, 0);
    }
  }
  .taxo-bgd {
    min-height: 240px;
  }
  .submenuwrap {
    display: flex;
    justify-content: center;
    .hamburger {
      position: static;
      padding: 0;
      height: 35px;
      margin-left: 20px;
      z-index: 10;
      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: #fff;
      }
    }
  }
  .btn-menufamily {
    width: 320px;
  }
  .mainslider {
    min-height: auto;
  }
  .page-search  {
    .teaserprod-img::after {
      display: none;
    }
  }

  .tabs .fiche dl{
    dt,
    dd {
      width: 100%;
      max-width: 100%;
    }
  }
  .tabs .fiche dl dt{
    padding-bottom: 10px;
  }
  .tabs .fiche dl dd{
    padding-top: 10px;
    border-top: none;
  }
}

/* Environnement mobile : Tablettes & Téléphones
==================================================*/
@media only screen and (max-width: $desk - 1) {
  .mainslider{
    min-height: 10px;
  }
  .news h2, .products>.wrapper>h2, .reassurance h2{
    padding-bottom: 20px
  }
  .bloc-expertises .views-rows{
    max-width: 674px;
    @include centered-auto;
  }
  .products .listprod {
    max-width: 634px;
  }
  .reassurance .field--name-field-reassurance-paragraph{
    flex-wrap: wrap !important;
    max-width: 600px !important;
    @include centered-auto;
  }
  .reassurance{
    padding-bottom: 30px;
  }
  .reassurance h2 {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .homesocial{
    padding-right: 0;
    padding-top: 30px;
    justify-content: center;
    [data-lity]{
      max-width: 400px;
      margin-top: 30px;
      .video-img {
        height: 220px;
      }
    }
    .full{
      max-width: 400px;
    }
  }
  .contacthome .wrapper{
    justify-content: center;
  }
  .livreblanc{
    .field--name-field-bgd-img {
      height: 400px;
      position: relative;
      overflow: hidden;
      img{
        position: absolute;
        top:0;
        left:-200px;
        height:400px;
        width:auto;
        max-width: 99999em;
      }
    }
    &-cont{
      padding-right: 60px;
    }
  }
  .tabs>.wrapper:first-child{
    padding-left: 12px;
    padding-right: 12px;
    flex-wrap: wrap;
    padding-top: 22px;
  }
  .product-illus{
    flex-wrap: wrap;
    justify-content: center;
  }
  .tabs .tab{
    width: 30%;
    margin-top: 8px;
  }
  .wrap-cat{
    justify-content: space-between;
  }
  .expertise-categories{
    width:30%;
  }
  .wrap-expertise{
    width:60%;
    margin:0;
    padding-right: 15px;
  }
  .page-node-type-page{
    .page.full{
      padding-left: 17px;
      padding-right: 17px;
    }
  }
  body {
    #tarteaucitronAlertSmall {
      transform: scale(0.5);
      transform-origin: bottom right;
    }
  }

  .tabs{
    overflow: hidden;
    .wrapper:first-child {
      flex-wrap: nowrap;
      overflow-y:scroll;
      .tab {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .phab-full {
    width: 100%;
  }
  .footer {
    .field--name-body {
      padding-bottom: 30px;
      span {
        display:block;
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  .corporate .corpo-item .cont {
    padding-top: 15px;
  }
}

/* Phablet (Gros téléphone mobile)
==================================================*/
@media only screen and (max-width: $phab) {

  #breadcrumb ol li {
    display: inline-flex;
    span{
      display: inline-block;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .teaserprod-img
  .wrap-messages{
    width: auto;
    margin:0;
    left:15px;
    right:15px;
  }
  .slider-cont{
    padding: 18px 20px;
    h1{
      @include font-size-root(26);
      line-height: 36px;
    }
  }
  .corporate{
    padding-top: 30px;
  }
  .corporate h2{
    @include font-size-root(26);
  }
  .corporate .corpo-item .cont h2 {
    @include font-size-root(24);
  }
  .teaserprod{
    margin-left: auto;
    margin-right: auto;
  }
  .corporate .corpo>div{
    width:100%;
  }
  .corporate .corpo-item {
    height: 300px;
    a,
    div{
      height: 100%;
    }
    img{
      height:100%;
      width:100%;
      object-fit: cover;
    }
  }
  .corporate .corpo-item .cont{
    height: 300px;
    bottom:auto;
    padding: 10px 20px;
  }
  .reassurance .field--name-field-reassurance-paragraph{
    flex-wrap: wrap !important;
    &>.field--item{
      margin-left:auto;
      margin-right: auto;
    }
  }
  .news-head{
    padding-left:17px;
    padding-right:17px;
  }

  .news{
    .bx-wrapper .bx-controls-direction a{
      border-color:$color-dark;
      &::before{
        color:$color-dark;
      }
    }
  }
  .homesocial{
    padding: 0;
    .feed {
      width: 256px;
      padding-right: 0;
    }
    .links {
      width: 50px;
    }
    [data-lity]{
      padding-top: 20px;
      .video-img{
        height: 174px;
        width:320px;
        margin-left:auto;
        margin-right:auto;
      }
    }
    .full{
      text-align: center;
    }
  }
  .footer .menu{
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .footer #block-logosfooter{
    .text-align-justify{
      img{
        margin-bottom: 8px;
      }
    }
  }
  .page-products .bloc-expertises .views-rows>div{
    margin-left: auto;
    margin-right: auto;
  }
  .livreblanc{
    .wrapper{
      padding: 15px;
    }
    .field--name-field-bgd-img img{
      opacity: 0.5;
    }
    &-cont{
      padding: 30px;
      &_title{
        @include font-size-root(20);
      }
      &_subtitle{
        @include font-size-root(16);
      }
      &_body{
        .field--name-body{
          p{
            padding-bottom: 10px;
            line-height: 20px;
          }
        }
        .livreblanc-cont_button{
          &+p{
            display: none;
          }
        }
      }
      &_body + &_button{
        .btn-submit{
          line-height: 18px;
          @include font-size-root(14);
        }
      }
    }
  }
  .pagination .btn-submit{
    min-width: 140px;
  }
  .tabs .tab{
    width: 48%;
    margin-top: 8px;
  }
  .product-illus>div{
    width:90%;
  }
  .product-illus>.product-illus_imgs{
    width: 100%;
  }
  .product-illus_imgs{
    height: auto;
    margin-bottom: 30px;
  }
  .product-illus .actions>div{
    width: 100%;
  }
  .product-illus .slider-main .slide{
    padding: 10px;
  }
  .product-illus h1{
    @include font-size-root(30);
  }
  .tabs .tab-content .wrapper>div{
    padding:20px;
  }
  .wrap-cat {
    flex-wrap: wrap;
    justify-content: center;
  }
  .expertise-categories{
    width:90%;
    padding-bottom: 20px;
  }
  .wrap-expertise{
    width:90%;
    margin:0;
    padding-right: 0;
  }
  .bloc-expertises .views-rows>div{
    margin-left:auto;
    margin-right: auto;
  }
  .videos .video a {
    flex-wrap: wrap;
  }
  .videos .video-cont,
  .videos .video-img{
    width:100%;
  }

  .homesocial {
    padding-bottom: 40px;
    [data-lity] {
      height: 231px;
    }
  }
  .tabs .fiche dl {
    width: 100%;
    dd,
    dt {
      width: 100%
    }
  }
  .tabs {
    position: relative;
      &::before {
        content:$ico-smallarrow;
        font-family: 'leader';
        display: flex;
        position: absolute;
        top:30px;
        height: 60px;
        right:0;
        width:30px;
        transform: rotate(180deg);
        background: #fff;
        align-items: center;
        justify-content: center;
        font-weight: 900;
      }
  }
}

/* Téléphones
==================================================*/
@media only screen and (max-width: $palm){

}
