html {
  overflow-x: hidden;
}

// animation sur les a href
a {
  outline: 0;
  transition: $transitionbtn;
  &:hover {
    transition: $transitionbtn;
  }
}

.menu-ancres a {
  transition: $transitionbtn;
  &:hover {
    transition: $transitionbtn;
    &::after {
      transition: $transitionbtn;
    }
  }
}

.menu-ancres a {
  transition: $transitionbtn;
  &::after {
    transition: $transitionbtn;
  }
}

input[type=submit] {
  transition: $transitionbtn;
  &:hover{
    transition: $transitionbtn;
  }
}
.event input[type="radio"] {
  transition: $transitionbtn;
  & ~ label {
    transition: $transitionbtn;
  }
  &:hover ~ label {
    transition: $transitionbtn;
  }
}

/* animation des blocs */

.js .anim {
  opacity: 0;
  transition: translate3d 900ms ease;
  transform: translate3d(0, 100px, 0);
}

.js .anim.active {
  opacity: 1;
  transition: translate3d 900ms ease;
  transform: translate3d(0, 0px, 0);
}

/*************************************** COOKIES ****************************************************/

.iccookies_container {
  top: 0;
  bottom: auto;
  z-index: 1000;
  background-color: $color-second;
  opacity: 1;
  font-weight: 500;
  height: 56px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
  .close_iccookies {
    background-color: #000;
    font-weight: 400;
    font-style: normal;
    width: 67px;
    text-align: center;
    display: inline-block;
    margin-left: 8px;
    line-height: 32px;
  }
}

.iccookies_container~.layout {
  .langue select,
  .wrap-hamburger,
  #logo {
    margin-top: 50px;
  }
}

.iccookies_container[style="display: none;"]~.layout {
  .langue select,
  .wrap-hamburger,
  #logo {
    margin-top: 0;
  }
}

.visually-hidden {
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
}

/* base IC */

a {
  text-decoration: none;
  color: #e90051;
}

body {
  font: normal 17px/25px, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: $color-body;
}

h1 {
  font-size: 53px;
  color: #004379;
  margin-bottom: 15px;
}

h2 {
  font-size: 34px;
  color: #004379;
  border-bottom: 2px solid #e50043;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

h3 {
  font-size: 17px;
  color: #0f4d86;
}

.legend {
  color: #004379;
  font-size: 15px;
  margin: 10px 0;
}

body .main-container {

}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

body {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  >div:not(.toolbar){
    font-family: $fontfam;
    font-style: normal;
    font-weight: 300;
  }
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  padding-top: 215px;
}
.site-head{
  background-color: #fff;
  position: absolute;
  top:0;
  left:0;
  right: 0;

}
.site-content {
  flex: 1 0 auto;
}
.wrap {
  margin: 0 auto;
  width: 1400px;
}
.fixed-menu{
  .site-head{
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 100;
  }
  .familly{
    position: fixed;
    left:0;
    right:0;
    top: 158px;
    z-index: 50;
  }
  .region-header{
    padding-top: 8px;
  }
  #block-leader-logosvg{
    margin-left: 0;
    margin-right: auto;
  }
  #logo{
    width: 150px;
    position: relative;
    top: 15px;
  }
  header[role=banner]{
    height: 94px;
  }
}

.adminimal-admin-toolbar.fixed-menu .familly {
  top: 197px;
}

#menu-header a:hover {
  text-decoration: underline;
}

.wrap::after {
  display: block;
  clear: both;
  content: " ";
}
.wrap-messages{
  width: 620px;
  position: fixed;
  top:10px;
  left:50%;
  margin-left: -(620px/2);
  z-index: 100011;
  background: #fff;
  overflow: hidden;
}
.close,
.alert .close{
  position: absolute;
  width:32px;
  height:32px;
  line-height:32px;
  text-align: center;
  top:0px;
  right:0px;
  color:#000;
  font-family: Arial, Helvetica, sans-serif;
  background: transparent;
  color:#fff;
  @include font-size-root(26);
  font-weight: 700;
  text-decoration:none;
}
.alert{
  padding: 35px;
  color:#fff;
  background-color: #2196F3;
  font-weight: 700;
  a{
    color:#fff !important;
    text-decoration: underline;
  }
  &-danger,
  &-error{
    color:#fff;
    background-color: #f44336;
  }
  &-success{
    color:#fff;
    background-color: #4CAF50;
  }
  &-warning{
    color:#fff;
    background-color: #ff9800;
  }
}

// *************************************** HEADER ****************************************************
header[role="banner"]{
  height: 151px;
  border-bottom: 1px solid #cbcbcb;

}
#logo{
  width:220px;
  display: block;
  transform: scale(1.3);
}
.region-header{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 40px 40px 0;
  position: relative;
  &>section:first-child{
    margin-left: 0;
    margin-right: auto;
  }
}
.you-are, .lang{
  border-bottom:2px solid #dadada;
  display: flex;
  align-items: center;
  &.lang{
    margin-left: 30px;
    .nav-children .children>li{
      min-width: 70px;
      max-height: 36px;
      height: 100%;
    }
  }
  &.you-are{
    margin-right: 60px;
  }
  .ico-arrowTB {
    &::before {
      @include font-size-root(12);
    }
  }
  button, a{
    background-color: #fff;
    color:$color-dark;
    text-transform: uppercase;
    font-weight:700;
    display: inline-block;
    line-height: 32px;
  }
  .menu>li>button, a{
    padding-right: 12px;
  }
  .nav-children .children{
      z-index: 999;
      box-shadow: 0px 1px 5px rgba(#ccc, 0.5);
      width: calc(100% + 12px);
      li{
        width: 100%;
        a{
          display: flex; align-items: center; justify-content: space-between;
          border-bottom:1px solid $color-lightgrey;
          line-height: 32px;
          padding-top: 3px;
          background: #fff;
          padding-left: 10px;
          padding-right: 10px;
          z-index: 9999;
          &::after {
            content: '';
            background-position-y: 38%;
            height: 20px;
            display: block;
            width: 20px;
            background-repeat: no-repeat;
            margin-left: 4px;
          }
        }
      }

    li.fr a{
      max-height: 36px;
      &::after{
        background-image: url("../images/fr-flag.png");
      }
    }
    li.es a{
      &::after{
        background-image: url("../images/es-flag.png");
      }
    }
    li.de a{
      &::after{
        background-image: url("../images/ger-flag.png");
      }
    }
    li.en a{
      &::after{
        background-image: url("../images/uk-flag.png");
      }
    }
  }
  .nav-children {
    > li {
      button {
        display: flex; align-items: center;
        justify-content: space-between;
        &::after {
            content: '';
            background-position-y: 38%;
            height: 20px;
            display: block;
            width: 20px;
            background-repeat: no-repeat;
            margin-left: 4px;
        }
      }
      &.fr {
        button {
          &::after{
             background-image: url("../images/fr-flag.png");
          }
        }
      }
      &.es {
        button {
          &::after{
             background-image: url("../images/es-flag.png");
          }
        }
      }
      &.de {
        button {
          &::after{
             background-image: url("../images/ger-flag.png");
          }
        }
      }
      &.en {
        button {
          &::after{
             background-image: url("../images/uk-flag.png");
          }
        }
      }
    }
  }
}
.btn-open-search{
  background: none;
  height: 33px;
  width:33px;
  line-height: 33px;
  text-align: center;
  outline:0;
  i{
    vertical-align: middle;
    &::before{
      @include font-size-root(16);
      position: relative;
      top:-2px;
    }
  }
  &.open-search{
    & ~ .search-block-form{
      margin-top: 0;
      transition: margin-top .2s ease-in-out;
    }
  }
}
header .header-search{
  .search-block-form{
    position: absolute;
    top:50px;
    right:0;
    left: 0;
    margin-top: -100%;
    transition: margin-top .2s ease-in-out;
    z-index: 99999;
    padding: 18px 20px;
    .form--inline {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .control-label {
      display: none;
    }

  }
  .btn-close{
    @include  close-button (28px, 3px, #fff);
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: -40px;
    right: 8px;
    outline:0;
  }
  .btn-submit{
    width:100%;
  }
}
.social {
  // à remettre quand on activera la traduction
  // padding-right: 70px;
  a{
    display: inline-block;
    background: none;
    height: 33px;
    width: 33px;
    line-height: 33px;
    text-align: center;
    outline: 0;
    color: $color-dark;
    &::before{
      @include font-size-root(12);
    }
  }
}

.lang .links{
  display: flex;
  justify-content: flex-end;
  li{
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    span{
      display: inline-block;
      text-transform: uppercase;
      @include font-size-root(13);
      height: 33px;
      line-height: 33px;
      padding: 0 6px;
    }
    a{
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      font-size: 0;
    }
    &.is-active{
      span{
        font-weight: 700;
      }
    }
    &::before{
      content: "";
      display: block;
      width: 38px;
      height: 2px;
      background-color: #dedede;
    }
    &:first-child{
      &::before{
        display: none;
      }
    }
  }
}
.top-menu{
  height:0;
  width:100%;
  position: absolute;
  bottom: 13px;
  nav[role="navigation"]{
    display: flex;
    justify-content:flex-end;
  }
  .menu{
    position: relative;
    z-index: 11;
    li{
      vertical-align: top;
    }
    li a{
      height:48px;
      display:flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      padding: 0 30px;
    }
    .tel{
      color:$color-dark;
      font-weight: 700;
      letter-spacing: 0.03em;
      @include font-size-root(17);
      &:first-child{
        display:none;
      }
    }
    .contact{
      background-color: $color-second;
      color:#fff;
      font-weight: 900;
      font-style: italic;
      position: relative;
      letter-spacing: 0.03em;
      @include font-size-root(15);
      &::before{
        content:'';
        display: block;
        height:0;
        width:0;
        border-right:16px solid $color-second;
        border-top:48px solid transparent;
        position: absolute;
        top:0;
        left:-16px;
      }
    }
  }
}
.navigation{
  border-bottom:1px solid #ededed;
  position: relative;
  .main-menu>nav>ul>li{
    min-width: 211px;
    &:first-child {
      a {
        padding-left: 0;
      }
    }
  }
  .has-children {
    position: static;
    &:hover{
      .children{
        border-top:1px solid #ededed;
        background-color: #fff;
        right:0;
        left:0;
        z-index: 11;

      }
    }

  }
  .contextual-region{
    position: static;
  }
}
#breadcrumb{
  max-width: $page;
  @include centered-auto;
  padding: 20px 22px;
  ol{
    display: flex;
    flex-wrap: wrap;
    li a,
    li{
      color:$color-dark;
      @include font-size-root(13);
      font-weight: 300;
      letter-spacing: 0.03em;
    }
    li:last-child{
      a,
      a span{
        font-weight: 700;
      }
      &::after{
        display: none;
      }
    }
    li{
      &::after{
        content:'>';
        display: inline-block;
        padding:  0 5px;
      }
    }
    a:hover{
      text-decoration: underline;
    }
  }
}
.children{
  &>a{
    position: absolute;
    top:20px;
    left:0;
    right:0;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-left: 20%;
    color:rgba($color-dark, 0.05);
    @include font-size-root(100);
    font-weight: 900;
    text-transform: uppercase;
    font-style: italic;
    .light{
      font-weight: 500;
      padding-right: 12px;
    }
    display: none;
  }
}
.mega-menu{
  padding: 70px 124px;
  position: relative;

  .item-list{
    max-width: $page;
    @include centered-auto;
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      li{
        width:25%;
        a{
          color:#828282;
          font-weight: 500;
          font-style: italic;
          @include font-size-root(15);
          text-transform: uppercase;
          display: block;
          padding: 10px 0;
          max-width: 250px;
          transition: all .2s ease-in-out;
          &:hover{
            font-weight: 900;
            color: $color-dark;
            position: relative;
            &::before{
              content: $ico-deco;
              display: block;
              position: absolute;
              font-family: 'leader' !important;
              width:10px;
              height: 12px;
              overflow: hidden;
              @include font-size-root(10);
              left: -18px;
              top: 12px;
              transition: all .2s ease-in-out;
            }
          }
        }
      }
    }
  }
}
.region-navigation{
  max-width: $page;
  @include centered-auto;
  display: flex;
  padding: 0 22px;
  .menu{
    display: flex;
    &>li{
      &>span>a,
      &>a{
        height: 63px;
        padding: 0 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        font-style: italic;
        letter-spacing: 0.03em;
        color:$color-dark;
        text-align: center;
        @include font-size-root(14);
        text-transform: uppercase;
        position: relative;
        max-width: 311px;
        &::after,
        &::before{
          content:'';
          height: 5px;
          width:0;
          background-color:$color-second;
          transition: width .3s ease-in-out;
          position: absolute;
          bottom:-1px;
          left:50%;
        }
        &::before{
          left:auto;
          right:50%;
        }
        &:hover{
          &::after,
          &::before{
            width:50%;
            transition: width .3s ease-in-out;
          }
        }
      }
      &>.is-active,
      &>.active>a{
        &::before,
        &::after{
          width: 50%;
        }
      }
    }
  }
}


// *************************************** ACCUEIL ****************************************************
// *************************************** CONTENU ****************************************************
// ***** fil d'ariane ****
#breadcrumbs {
  padding: 20px 0;
  font-size: 11px;
}
#breadcrumbs li {
  float: left;
  padding: 0 2px;
}
#breadcrumbs li a {
  color: #003c65;
}
// ******************* CONTENU **************************
section.content{
  a{
    color:$color-dark;
    text-decoration: underline;
  }
}
// ***************** Contact (formulaire) *******************************

// ********************************* Blog ********************************

//******************* ASIDE **************************
aside {
  float: right;
}
// *************************************** PIED DE PAGE ****************************************************
.footer{
  background: $color-dark;
  color:#fff;
  .inner{
    padding: 58px 80px;
  }
  p{
    @include font-size-root(16);
    line-height: 33px;
    padding: 0;
    letter-spacing: 0.03em;
    strong{
      font-weight: 700;
    }
  }
  .menu{
    text-align: right;
    padding-bottom: 70px;
    li{
      a{
        color:#fff;
        font-weight: 900;
        font-style: italic;
        @include font-size-root(14);
        text-transform: uppercase;
        display: inline-block;
        padding-left: 30px;
        &:hover{
          color:$color-first;
        }
      }
    }
  }
  #block-logosfooter{
    .field--name-body{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    p{
      width: 100%;
      max-width: 511px;
      padding-bottom: 38px;
      &:last-child{
        padding-bottom: 0;
      }
    }
  }
}

.region-share{
  a{
    vertical-align: top;
  }
  [class*="ico-"]{
    padding-right: 10px;
    &::before{
      @include font-size-root(30);
    }
  }
  .ico-fbplain{
    color:#4460a0;
  }
  .ico-twitter{
    color:#00aaec;
  }
  .ico-print{
    color:#ff853b;
  }
  .ico-mail{
    color:#708a97;
  }
  .addtoany_list.a2a_kit_size_32 a > span{
    vertical-align: top;
    background-color:transparent !important;
    svg path{
      fill:$color-dark;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding-bottom: 46px;
  .pager__item{
    a{
      text-decoration: none;
      display: inline-block;
      padding: 0 12px;
      line-height: 50px;
      @include font-size-root(15);
      font-weight: 700;
    }
  }
  .pager__item--previous,
  .pager__item--next{
    display: none;
  }
  .pager__item--last{
    padding-left: 8px;
    a{
      font-size: 0;
      &::before{
        font-family: 'leader';
        content: $ico-smalldoublearrow;
        display: inline-block;
        @include font-size-root(15);
        transform: rotate(180deg);
        position: relative;
      }
    }
  }
  .pager__item--first{
    padding-right: 8px;
    a{
      font-size: 0;
      &::before{
        font-family: 'leader';
        content:$ico-smalldoublearrow;
        @include font-size-root(15);
        display: inline-block;
      }
    }
  }
  .btn-submit{
    border:1px solid $color-dark;
    background-color:transparent;
    height: 50px;
    min-width:120px;
    margin: 0 10px;
  }
}

body{
  .bx-wrapper .bx-pager.bx-default-pager a{
    background-color:#c1c1c1;
    height:8px;
    width:8px;
    border-radius: 4px;
  }
  .bx-wrapper .bx-pager.bx-default-pager a.active{
    background-color:$color-second;
  }
}

.adminimal-admin-toolbar.path-taxonomy{
  .wrap-messages{
    margin-top: 90px;
  }
}
.adminimal-admin-toolbar.toolbar-tray-open{
  &.fixed-menu .familly{
    top: 237px;
  }
}


body{
  #tarteaucitronPercentage,
  #tarteaucitronAlertBig #tarteaucitronCloseAlert,
  #tarteaucitronAlertBig #tarteaucitronPersonalize{
    background-color: $color-second;
    color:#fff;
  }
}
/* pages */
.ajax-load{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index: 9999;
  background-color: rgba(#fff,0.5);
  background-image: url(../images/loading.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50px auto;
}

.search-is-open{
  &::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* fallback */
    width: 100vw;
    height: 100%; /* fallback */
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    /*background-color: rgba(blue, 0.5);*/
    background-image: linear-gradient(to bottom, rgba(black, 0.5), transparent);
    z-index: 10000;
  }
}

.contacthome .form-type-processed-text {
  p {
    max-width: 999em;
    @include font-size-root(9);
    line-height: 10px;
    padding-bottom: 7px;
  }
}

@import 'pages/home';
@import 'pages/comparator';
@import 'pages/search';
@import 'pages/products';
@import 'pages/expertise';
@import 'pages/page';
@import 'pages/webform';
@import 'pages/ie';
