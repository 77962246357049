@import "variables";


$icomoon-font-path: "fonts" !default;

@font-face {
  font-family: 'leader';
  src:  url('#{$icomoon-font-path}/leader.eot?ft1ba7');
  src:  url('#{$icomoon-font-path}/leader.eot?ft1ba7#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/leader.ttf?ft1ba7') format('truetype'),
    url('#{$icomoon-font-path}/leader.woff?ft1ba7') format('woff'),
    url('#{$icomoon-font-path}/leader.svg?ft1ba7#leader') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'leader' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ico-youtube {
  &:before {
    content: $ico-youtube; 
  }
}
.ico-smallarrow {
  &:before {
    content: $ico-smallarrow; 
  }
}
.ico-fbplain {
  &:before {
    content: $ico-fbplain; 
  }
}
.ico-mail {
  &:before {
    content: $ico-mail; 
  }
}
.ico-plus {
  &:before {
    content: $ico-plus; 
  }
}
.ico-print {
  &:before {
    content: $ico-print; 
  }
}
.ico-file {
  &:before {
    content: $ico-file; 
  }
}
.ico-video {
  &:before {
    content: $ico-video; 
  }
}
.ico-arrowTB {
  &:before {
    content: $ico-arrowTB; 
  }
}
.ico-deco {
  &:before {
    content: $ico-deco; 
  }
}
.ico-facebook {
  &:before {
    content: $ico-facebook; 
  }
}
.ico-instagram {
  &:before {
    content: $ico-instagram; 
  }
}
.ico-linkedin {
  &:before {
    content: $ico-linkedin; 
  }
}
.ico-magnify {
  &:before {
    content: $ico-magnify; 
  }
}
.ico-more {
  &:before {
    content: $ico-more; 
  }
}
.ico-pinterest {
  &:before {
    content: $ico-pinterest; 
  }
}
.ico-twitter {
  &:before {
    content: $ico-twitter; 
  }
}


