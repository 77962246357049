
.btn-submit{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  height: 45px;
  line-height: 48px;
  background-color: $color-second;
  color: #fff;
  @include font-size-root(16);
  text-transform: uppercase;
  min-width: 251px;
  text-align: center;
  padding: 0 25px;
  font-family: $fontfam;
  font-weight: 700;
  &:hover{
    background-color: #000;
  }
  i{
    margin-left: 10px;
    &::before{
      @include font-size-root(40);
    }
  }
}

.btn-readmore{
  text-decoration: none !important;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  position: relative;
  &::before{
    content:'';
    width:45px;
    height: 1px;
    display: block;
    position: absolute;
    bottom:-5px;
    left:0;
    background-color: #000;
  }
}

.form-submit{
  @extend .btn-submit;
  background-color: $color-second;
  outline: 0;
}
form{
  label{
    display: block;
    font-weight: bold;
    @include font-size-root(15);
    text-transform: uppercase;
    line-height: 28px;
  }
}
.form-item{
  padding: 4px 0;
  input{
    border:1px solid $color-second;
    height:45px;
    padding: 0 14px;
    width: 100%;
    @include font-size-root(20);
    color: $color-dark;
    border-color: $color-second;
    -webkit-appearance: none;
    border-radius: 0;
    @include  input-placeholder {
      color: $color-dark;
    }
  }
  textarea{
    display: block;
    color: $color-dark;
    border:1px solid $color-second;
    padding: 0 14px;
    @include font-size-root(20);
    color: $color-dark;
    @include  input-placeholder {
      color: $color-dark;
    }
  }
  textarea:focus, input:focus, input[type]:focus{
    border-color: rgba($color-first, 0.5);
    box-shadow: 0 1px 1px rgba($color-second, 0.075) inset, 0 0 8px rgba($color-second, 0.6);
    outline: 0 none;
  }
  .description{
    padding-top:5px;
  }
  textarea.error,
  input.error{
    border-color:$color-first !important;
  }
  .form-required{
    color:$color-first;
  }
}

.form-actions {
  padding-top: 4px;
}
.user-login-form{
  max-width: 600px;
  padding: 30px 15px;
  margin: 0 auto;
}
.swal-modal{
  border-radius: 0;
  font-family: $fontfam;
}
.swal-button,
.swal-button.swal-button--confirm{
  border-radius: 0;
  background-color: $color-second;
}

.allnews{
  @extend .btn-readmore;
  display: flex;
  align-items: center;
  &::before{
    display: none;
  }
  i{
    color: $color-second;
    margin-left: 10px;
    &::before{
      @include font-size-root(32);
    }
  }
}

.form-item textarea{
  width:100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn-menufamily {
  @extend .btn-submit;
}