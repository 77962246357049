@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
    /* IE10+ specific styles go here */  
    .region-header > section:first-child{
        margin:0;
        position: absolute;
        left:40px;
        top:40px;        
    }
    .fixed-menu{
        .region-header > section:first-child{
            top:12px;  
        }
        .top-menu{
            bottom:-4px;
        }
    }
    .region-navigation .menu > li > a, 
    .region-navigation .menu > li > span > a{
        max-width: 249px;
    }

    .top-menu{
        bottom:-29px;
        left:40px;
    }
    @media only screen and (max-width: 1359px) {
        .region-navigation .menu > li > a, 
        .region-navigation .menu > li > span > a{
            padding-left: 10px;
            padding-right: 10px;
            max-width: 189px;
        }
        .top-menu{
            left:-20px;
        }
    }
 }