.bloc-expertises{
    .view-content{
        margin: 0 -17px;
    }
    .views-rows{
        display: flex;
        flex-wrap: wrap;
        &>div{
            margin: 0 17px;
            width: 282px;
        }
    }
}
body.expertises{
    .site-content{
       // background:url(../images/bgd_expertise.png) top right no-repeat;
    }
}

.page-expertises,
body.page-node-type-expertise {
    #block-navigationdroite {
        .menu {
            a[href*='expertises'] {
                &::before,
                &::after {
                    width: 50%;
                }
            }
        }
    }
}

.expertise-teaser{
    margin-bottom: 55px;
    &_illus{
        display: block;
        margin-bottom: 10px;
        box-shadow: 0 29px 72px -37px rgba(0,0,0,.48);
    }
    &_title{
        @extend .title-h2;
        text-decoration: none !important;
        position: relative;
        display: block;
        padding-left: 25px;
        &::before{
            content:'';
            width:7px;
            height:49px;
            display: block;
            position: absolute;
            top:0;
            left:0;
        }
    }
    h2{
        border:none;
    }
}

.wrap-cat{
    align-items: flex-start;
    padding-top: 77px;
}
.wrap-expertise{
    width: 916px;
    margin-left: 88px;
}
.expertise-categories{
    width:247px;
    .taxo{
        position: relative;
        background-color: #fff;
        height: 61px;
        width:100%;
        margin-bottom: 1px;
        overflow: hidden;
        cursor: pointer;
        a{
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            display: flex;
            align-items: center;
            padding-left: 35px;
            padding-right: 20px;
            text-decoration: none;
            font-weight: 700;
            @include font-size-root(16);
            text-transform: uppercase;
        }
        &>span{
            position: absolute;
            top:0;
            right:0;
            display: block;
            width: 7px;
            height: 100%;
            margin-right: 0;
            transition: margin-right .2s ease-in-out;
        }
    }
}
.wrap-expertise{
    h1{
        @extend .title-h1;
        
    }
    .field--name-description{
        p{
            @extend .p;
            line-height: 22px;
            text-align: left;
        }
        max-width: 655px;
        padding-bottom: 20px;
    }
}

.expertise.full{
    padding-bottom: 70px;
    h1{
        @extend .title-h1;
    }
    .field--name-field-tags{
        padding-bottom: 34px;
        @include font-size-root(16);
        a{
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .field--name-field-image{
        float: left;
        margin-right:44px;
        margin-bottom: 25px;
    }
    .field--name-body{
        @extend .body;
        padding-bottom: 70px;
    }
}
.cross-expertise{
    background-color: #fff;
    padding-bottom: 20px;
    .views-element-container{
        display: flex;
        width:920px;
        @include centered-auto;
    }
    &_title{
        padding-top:70px;
        padding-bottom:50px;
        @include font-size-root(28);
        text-transform: uppercase;
        strong{
            font-style: italic;
            font-weight: 900;
        }
    }
    
}
.bloc-expertises {
    .taxolink{
        font-weight: 900;
        font-style:italic;
        display: block;
        padding-top: 10px;
        padding-bottom: 15px;
        text-transform: uppercase;
        text-decoration: none;
    }
}

.page-node-type-article,
.page-node-type-expertise{
    .article.full .field--name-body,
    .expertise.full .field--name-body{
        max-width: 9999em;
        padding-top: 0;
        p {
            img.align-right {
                float: right;
                padding: 20px;
            }

            img.align-left {
                float: left;
                padding: 20px;
            }
        }
    }
    .block-addtoany-block{
        clear:both;
    }
}
